import React from "react"
import tw, { styled } from "twin.macro"

import CourseCard from "./CourseCard"
import SubHeader from "./SubHeader"

const CourseWrapper = styled.div`
  ${tw`grid grid-cols-1 md:grid-cols-2 gap-6 max-w-screen-xl mx-auto px-5`}
`

const CourseList = () => {
  return (
    <>
      <SubHeader>Nasze kursy</SubHeader>
      <CourseWrapper>
        <CourseCard
          link="/kursy-ksiegowosci/"
          subheader="kurs księgowości"
          header="od podstaw"
          description="Kurs księgowość od podstaw adresowany jest do osób, które dotychczas nie pracowały w księgowości, a chciałyby związać swoją przyszłość z zawodem księgowego"
          labels={["weekendowy", "wtorek i czwartek"]}
        />
        <CourseCard
          link="/kurs-samodzielny-ksiegowy/"
          subheader="kurs księgowości"
          header="dla zaawansowanych"
          description="Kurs samodzielny księgowy adresowany jest do osób, które planują rozszerzyć swoją wiedzę z zakresu rachunkowości potrzebną do efektywnej pracy w pionach finansowo-księgowych przedsiębiorstw."
          labels={["weekendowy"]}
        />
        <CourseCard
          link="/kurs-ksiegowosci-malych-firm/"
          subheader="kurs księgowości"
          header="małych firm"
          description=" Samodzielne prowadzenie księgowości małej firmy nie jest trudne. Kurs „księgowość małej firmy” przygotowuje do samodzielnego prowadzenia rozliczeń z fiskusem za pomocą ryczałtu od przychodów ewidencjonowanych lub podatkowej księgi przychodów i rozchodów (KPiR). "
          labels={["wtorek i czwartek"]}
        />
      </CourseWrapper>
    </>
  )
}

export default CourseList
