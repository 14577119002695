import React from "react"
import tw, { styled } from "twin.macro"
import { Link } from "gatsby"

const Container = styled.div`
  ${tw`bg-gray-200 shadow-sm rounded-lg`}
`

const ContentContainer = styled.div`
  ${tw`relative max-w-screen-xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16`}
`

const ContentInnerContainer = styled.div`
  ${tw``}
`

const SubHeader = styled.div`
  ${tw`text-xl leading-5 font-semibold text-gray-600`}
`

const Header = styled.h2`
  ${tw`mt-2 text-gray-700 text-2xl leading-9 font-bold tracking-tight sm:text-4xl sm:leading-10`}
`

const Description = styled.p`
  ${tw`mt-5 text-lg leading-7 text-gray-600`}
`

const ButtonContainer = styled.div`
  ${tw`mt-8`}
`

const ButtonWrapper = styled.div`
  ${tw`inline-flex rounded-md shadow`}
`

const Button = styled(Link)`
  ${tw`py-2 px-4 border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 shadow-sm focus:outline-none focus:shadow-outline-blue active:bg-blue-600 transition duration-150 ease-in-out`}
`

const CourseCard = ({ subheader, header, description, link }) => {
  return (
    <Container>
      <ContentContainer>
        <ContentInnerContainer>
          <SubHeader>{subheader}</SubHeader>
          <Header>{header}</Header>
          <Description>{description}</Description>
          <ButtonContainer>
            <ButtonWrapper>
              <Button to={link}>Więcej</Button>
            </ButtonWrapper>
          </ButtonContainer>
        </ContentInnerContainer>
      </ContentContainer>
    </Container>
  )
}

export default CourseCard
