import React from "react"
import Content from "../components/Content"
import PromoData from "../components/PromoData"
import ContentContainer from "../components/ContentContainer"
import ContentBiggerText from "../components/ContentBiggerText"
import ContentSmallerText from "../components/ContentSmallerText"
import CouseTermsList from "../components/CouseTermsList"
import CourseTermsWrapper from "../components/CourseTermsWrapper"
import Team from "../components/Team"
import Layout from "../components/Layout"
import CourseList from "../components/CourseList"
import SubHeader from "../components/SubHeader"
import Seo from "../components/Seo"
import { styled } from "twin.macro"

const BannerWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 50px 20px;

  img {
    max-width: 100%;
  }
`

export default function Home() {
  return (
    <Layout includeBanner>
      <Seo title="Kursy finansowe, księgowości, kadry i płace Wrocław" />
      <Content />
      <CourseList />
      <ContentContainer>
        <ContentBiggerText
          header="Jesteś w dobrych rękach"
          text="Zajęcia są prowadzone indywidualnie. "
        />
        <ContentSmallerText text="Każdy z uczestników kursu otrzymuje zbiór niezbędnych aktów prawnych, druków oraz autorskie materiały merytoryczne. Każdy z wymienionych kursów kończy się egzaminem. Cena zawiera wszystkie opłaty związane z kursem, a więc: wykłady, materiały teoretyczne oraz ćwiczenia, egzamin oraz zaświadczenie o ukończeniu kursu. Uczestnicy kursu otrzymują imienne zaświadczenie o ukończenia szkolenia zawierające informacje o ilości godzin z poszczególnego zakresu materiału, ilości godzin teorii oraz praktyki. " />
      </ContentContainer>
      <BannerWrapper>
        <a href="https://www.cicomputer.pl/" target={"_blank"} rel="noreferrer">
          <img src="/banner_symfonia.png" alt="CI-COMPUTER złoty" />
        </a>
      </BannerWrapper>
      <PromoData />
      <Team />
    </Layout>
  )
}
