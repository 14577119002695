import * as React from "react"
import ContentContainer from "./ContentContainer"
import ContentBiggerText from "./ContentBiggerText"
import ContentSmallerText from './ContentSmallerText'


const Content = () => {
  return (
    <ContentContainer>
      <ContentBiggerText
        header="Kursy księgowości we Wrocławiu"
        text=" Celem oferowanych przez nas szkoleń jest przekazanie Państwu
            rzetelnej wiedzy oraz praktycznych umiejętności niezbędnych w
            wykonywaniu zawodu księgowego. Każdy nasz klient ma możliwość wyboru
            optymalnego dla siebie rozwiązania edukacyjnego w zależności od
            poziomu dotychczasowej wiedzy z zakresu księgowości."
      />
      <ContentSmallerText text=" Podczas szkoleń uczestnicy zdobędą, podaną w przystępny sposób, wiedzę
          teoretyczną oraz będą uczestniczyć w licznych zajęciach praktycznych.
          To właśnie ilość i sposób prowadzenia zajęć praktycznych jest tym, co
          odróżnia nas od innych tego typu kursów." />
    </ContentContainer>
  )
}

export default Content
